import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Squat Clean 2-2-2-2\\@90% 1RM (if no hang max use 85% full clean)`}</p>
    <p>{`Glute Ham Raise 4-4-4-4 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Chief”`}</strong></p>
    <p>{`5 Rounds of`}</p>
    <p>{`Max Rounds in 3:00 of:`}</p>
    <p>{`3-Power Cleans (135/95)`}</p>
    <p>{`6-Pushups`}</p>
    <p>{`9-Squats`}</p>
    <p>{`1:00 rest between rounds.`}</p>
    <p>{`-Each round begins with Power cleans so only full rounds count.`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit Kids today at East at 9:30.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      